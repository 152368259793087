import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { expandNavbar, selectExpand, setRender, currentRender, setPosition, navPosition } from "../redux/navbarSlice";
import { useDispatch, useSelector } from "react-redux";
import NavBar from './navbar';

const Viewer = () => {

    const [ largeScreen, setLargeScreen ] = useState(window.innerWidth > 900);
    const [ gridScreen, setGridScreen ] = useState(window.innerWidth > 1000);
    const [ textScreen, setTextScreen ] = useState(window.innerWidth > 600);
    const [scrollPosition, setScrollPosition] = useState(0);
    
    const [ quoteHeight, setQuoteHeight ] = useState(null);
    const gridRef = useRef();
    const textRef = useRef();

    const navbarPos = useSelector(navPosition);

    const [ currentImage, setCurrentImage ] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            setLargeScreen(window.innerWidth > 900);
            setGridScreen(window.innerWidth > 1000);
            setTextScreen(window.innerWidth > 600);
        };
        window.addEventListener('resize', handleResize);

        if(gridScreen) {
            const gridHeight = gridRef.current.offsetHeight - 40;
            const textHeight = textRef.current.offsetHeight;
            setQuoteHeight(gridHeight - textHeight);
        }

        const imageInterval = setInterval(() => {
            setCurrentImage((prevState) => {
                if(prevState < 5) {
                    return prevState + 1
                } else {
                    return 1
                }
            })
        }, 5000);

        return() => {
            clearInterval(imageInterval);
            window.removeEventListener('resize', handleResize);
        }

    }, [gridRef, textRef, quoteHeight, navbarPos, currentImage]);

    const dispatch = useDispatch();
    const actualRender = useSelector(currentRender);
    const factsRef = useRef();
    const imageRef = useRef();
    
    const container = document.getElementById('root');
    const controlsLogo = useAnimation();
    const [ quoteRef, quoteView ] = useInView({
        threshold: 0.6
    });
    const [ quoteDiv, setQuoteDiv ] = useState(false);
    const [ logoRef, logoView ] = useInView({
        threshold: 0.4
    });
    const [ logoDiv, setLogoDiv ] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };   
        window.addEventListener('scroll', handleScroll);

        if(quoteView) {
            setQuoteDiv(true);
        }

        if(logoView) {
            setLogoDiv(true);
            controlsLogo.start({
                opacity: 1,
                x: 0,
                transition: {behavior: 'smooth', duration: 0.6}
            });
        } 

        let timeoutId;
        if(actualRender === 'facts') {
            factsRef.current.scrollIntoView({
                block: 'center',
            });
        }
        if(actualRender === 'portrait') {
            portraitRef.current.scrollIntoView({
                block: 'center'
            });
        }

        if(actualRender === 'home') {
            imageRef.current.scrollIntoView({
                block: 'start'
            })
        }
        timeoutId = setTimeout(() => {
            dispatch(setRender('start'));
        }, 1000)

        return() => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(timeoutId);
        }
    }, [scrollPosition, actualRender, quoteView, logoView]);


    const [ backgroundSetter, setBackgroundSetter] = useState(null);
    const blockRef = useRef();
    const boxRef = useRef();
    const coachRef = useRef();
    const handleLogoClick = (input) => {
        switch(input) {
            case 'coach':
                setBackgroundSetter('coach');
                coachRef.current.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
                break;
            case 'box':
                setBackgroundSetter('box');
                boxRef.current.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
                break;
            case 'block':
                setBackgroundSetter('block');
                blockRef.current.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            break;
        }
    }

    const portraitRef = useRef();

    const [ profileDiv, setProfileDiv] = useState(null);
    const [ profileText, setProfileText ] = useState(null);
    const handleProfileDiv = (input) => {
        switch(input) {
            case 'Ramon':
                if(profileDiv === null || profileDiv !== 'Ramon') {
                    setProfileDiv('Ramon');
                } else {
                    setProfileDiv(null);
                }
                setProfileText('Sport und Bewegung war für mich schon immer ein zentraler Bestandteil meines Lebens. Neben meiner eigenen Leidenschaft für diverse Sportarten, engagiere ich mich auch beruflich seit mehreren Jahren im Kontext der Bewegungsförderung. So leitete ich Outdoor-Fitnessangebote für Erwachsene und bin Trainer beim Roundnet Club Bern. Im Rahmen meines Studiums der Sportwissenschaft und der Psychologie entwickelte ich gemeinsam mit Alyssa, Nici und Jan das Projekt Rock the Block.  Parallel zu meinem Studium absolvierte ich die pädagogische Hochschule und unterrichte aktuell an der gibb Berufsfachschule und am Berufsbildungszentrum IDM das Fach Sozialwissenschaften.');
                break;
            case 'Nicolas':
                if(profileDiv === null || profileDiv !== 'Nicolas') {
                    setProfileDiv('Nicolas');
                } else {
                    setProfileDiv(null);
                }
                setProfileText('Ich wollte wie viele andere Kinder bereits sehr früh Fussballer werden, merkte dann aber sehr schnell, dass mir der Leistungssport nicht zusagt. Für mich stehen der Spass und die Geselligkeit im Vordergrund. Mit dieser Einstellung bestritt ich dann auch das Sportwissenschaftsstudium an der Universität Bern und war in unterschiedlichen Sportarten Trainer und leite weiterhin jeden Winter Schneesportlager. Inzwischen arbeite ich als Sozialarbeiter und versuche bei dem von mir mitentwickelten Angebot Rock the Block den Spass und die guten Erfahrungen durch Sport auch den Kindern zu ermöglichen.')
                break;
            case 'Alyssa':
                if(profileDiv === null || profileDiv !== 'Alyssa') {
                    setProfileDiv('Alyssa');
                } else {
                    setProfileDiv(null);
                }
                setProfileText('Sport war für mich schon immer meine grösste Leidenschaft. Um mir diese zum Beruf zu machen, habe ich Sportwissenschaft an der Universität Bern studiert und anschliessend die Ausbildung zur Lehrerin absolviert. Nun arbeite ich als Lehrerin in der Oberstufe, engagiere mich als J+S Expertin für den Skisport und möchte mit Rock the Block den Zugang zu Sport und Bewegung allen ermöglichen. Ich mache dies unteranderem deshalb, weil ich selbst durch den (Leistungs-)Sport viele positive Erfahrungen sammeln konnte. Ich sehe Sport als wichtiges Mittel für die Emotionskontrolle und möchte dieses Tool möglichst vielen Kindern zur Verfügung stellen.');
                break;
            case 'Jan':
                if(profileDiv === null || profileDiv !== 'Jan') {
                    setProfileDiv('Jan');
                } else {
                    setProfileDiv(null);
                }
                setProfileText('Seit meiner Kindheit war Sport für mich nicht nur eine Möglichkeit, mich auszuleben, sondern auch eine Quelle der Achtsamkeit, Kreativität und des Ausgleichs, um besser mit meinem ADHS klarzukommen. Die Passion für Sport und Bewegung entwickelte sich nicht aufgrund einer spezifischen Sportart, sondern vielmehr aufgrund der endlosen Möglichkeiten für Bewegung. Verantwortlich dafür war mein Sportlehrer während der Gymnasialzeit, welcher es vermochte, seine Begeisterung für Bewegung und Sport auf mich zu übertragen – ein Umstand, der mich stark beeindruckt hat und mich letztendlich dazu bewogen hat, selbst Sportlehrer zu werden. Die Erkenntnis, dass Bewegungsmangel weltweit zunimmt und dass insbesondere Menschen mit Migrationshintergrund davon betroffen sind, hat mich zusätzlich motiviert, am Projekt Rock the Block mitzuwirken.');
                break;
            case 'Pascal':
                if(profileDiv === null || profileDiv !== 'Pascal') {
                    setProfileDiv('Pascal');
                } else {
                    setProfileDiv(null);
                }
                setProfileText('Der Sport war und ist in meinem Leben nicht nur eine Leidenschaft, sondern auch eine Art zu rocken! Seit Jahren bin ich nicht nur als enthusiastischer Athlet aktiv, sondern auch beruflich im Bereich der Bewegungsförderung tätig. Ich bringe Schwung in Sportcamps der Organisation Plusport, pushe Bewegungsprogramme im Turnverein und gebe als Coach bei einem Fussballverein mein sportliches Feuer weiter. Neugierig wie ein Entdecker und kreativ wie ein Künstler, bin ich auf der Suche nach neuen Trends und Herausforderungen im Sport. Neben dem Adrenalin des Sports setze ich mich auch für die Nachhaltigkeit ein, denn für mich bedeutet Leben nicht nur atmen, sondern auch die Welt ein bisschen besser zu machen.');
                break;
        }
    }

    const [ firstRef, firstView ] = useInView({
        threshold: 0.4,
        triggerOnce: true
    });
    const [ secondRef, secondView ] = useInView({
        threshold: 0.4,
        triggerOnce: true
    });
    const [ thirdRef, thirdView ] = useInView({
        threshold: 0.4,
        triggerOnce: true
    });
    const [ fourthRef, fourthView ] = useInView({
        threshold: 0.4,
        triggerOnce: true
    });
    const [ fifthRef, fifthView ] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });
    const [ sixthRef, sixthView ] = useInView({
        threshold: 0.4,
        triggerOnce: true
    });
    const [ desktopRef, desktopView ] = useInView({
        threshold: 0.4,
        triggerOnce: true
    });
    const [ firstNumber, setFirstNumber ] = useState(0);
    const [ secondNumber, setSecondNumber ] = useState(262);
    const [ thirdNumber, setThirdNumber ] = useState(3960);
    const [ fourthNumber, setFourthNumber ] = useState(105);
    const [ fifthNumber, setFifthNumber ] = useState(0);
    const [ sixthNumber, setSixthNumber ] = useState(76);

    useEffect(() => {
        
        const desktopInterval = setInterval(() => {
                const target1 = 21.2;
                const target2 = 282;
                const target3 = 3980;
                const target4 = 125;
                const target5 = 5.7;
                const target6 = 96;
                const step = 1;
    
               if(desktopView) {
                setFirstNumber(prevNumber => Math.min(prevNumber + step, target1));
                setSecondNumber(prevNumber => Math.min(prevNumber + step, target2));
                setThirdNumber(prevNumber => Math.min(prevNumber + step, target3));
                setFourthNumber(prevNumber => Math.min(prevNumber + step, target4));
                setFifthNumber(prevNumber => Math.min(prevNumber + step, target5));
                setSixthNumber(prevNumber => Math.min(prevNumber + step, target6));
               }
            }, 60);

        return () => clearInterval(desktopInterval);
    }, [desktopView]);


    useEffect(() => {

        const interval = setInterval(() => {
            const target1 = 21.2;
            const target2 = 282;
            const target3 = 3980;
            const target4 = 125;
            const target5 = 5.7;
            const target6 = 96;
            const step = 1;
                if (firstView) {
                  setFirstNumber(prevNumber => Math.min(prevNumber + step, target1));
                } 
                if(secondView) {
                    setSecondNumber(prevNumber => Math.min(prevNumber + step, target2));
                }
                if(thirdView) {
                    setThirdNumber(prevNumber => Math.min(prevNumber + step, target3));
                }
                if(fourthView) {
                    setFourthNumber(prevNumber => Math.min(prevNumber + step, target4));
                }
                if(fifthView) {
                    setFifthNumber(prevNumber => Math.min(prevNumber + step, target5));
                }
                if(sixthView) {
                    setSixthNumber(prevNumber => Math.min(prevNumber + step, target6));
                }       
        }, 60);

        return () => {
            clearInterval(interval);
        }
    }, [firstView, secondView, thirdView, fourthView, fifthView, sixthView]);

    const quote = 'Aktive und gesunde Quartiere in der Schweiz, in denen die Bewohnenden miteinander eine nachhaltige Bewegungskultur leben.';
    const words = quote.split(' ');
    const quoteArray = [];

      for(let i = 0; i < words.length; i++) {
        quoteArray.push(words.slice(i, i + 1).join(' '));
      }

    return(
        <>
        <NavBar/>
        <div className = "image-container" ref = {imageRef}>

            <motion.img className = "images" src = "/images/viewer/hide.jpg" alt = "kids"
                intial = {{opacity: 1}}
                animate = {{opacity: currentImage !== 1 ? 0 : 1}}
                transition= {{duration: 2, delay: currentImage !== 1 ? 0.5 : 0}}
                style = {{zIndex: 5}}
            />
            <motion.img className = "images" src = "/images/viewer/catch.jpg" alt = "kids"
                intial = {{opacity: 1}}
                animate = {{opacity: currentImage !== 2 ? 0 : 1}}
                transition= {{duration: 2, delay: currentImage !== 2 ? 0.5 : 0}}
                style = {{zIndex: 4}}
            />
            <motion.img className = "images" src = "/images/viewer/rope.jpg" alt = "kids"
                intial = {{opacity: 1}}
                animate = {{opacity: currentImage !== 3 ? 0 : 1}}
                transition= {{duration: 2, delay: currentImage !== 3 ? 0.5 : 0}}
                style = {{zIndex: 3}}
            />
            <motion.img className = "images" src = "/images/viewer/kids.jpg" alt = "kids"
                intial = {{opacity: 1}}
                animate = {{opacity: currentImage !== 4 ? 0 : 1}}
                transition= {{duration: 2, delay: currentImage !== 4 ? 0.5 : 0}}
                style = {{zIndex: 2}}
            />
            <motion.img className = "images" src = "/images/viewer/juggle.jpg" alt = "kids"
                intial = {{opacity: 1}}
                animate = {{opacity: currentImage !== 5 ? 0 : 1}}
                transition= {{duration: 2, delay: currentImage !== 5 ? 0.5 : 0}}
                style = {{zIndex: 1}}
            />

            <div className = "image-title">{largeScreen && <p>«Für aktive und gesunde Quartiere!»</p>}</div>
            <img src = "/images/logos/RockLogo.png" className = "title-logo"/>
        </div>

        <div className = "ausgangslage" id = "ausgangslage">

            <h1>Ausgangslage</h1>
            <div className = {gridScreen ? "ausgangslage-grid" : ""}>

            <div className = "grid-text" ref = {gridRef}>
            {!textScreen && <p>Ausreichend Bewegung ist grundlegend für das menschliche Wohlbefinden. Bewegen sich Menschen zu wenig, hat dies schwerwiegende und langfristige Folgen für ihre physische und psychische Gesundheit. Bewegungsmangel betrifft oftmals Menschen mit tiefem sozioökonomischem Status und Migrationshin-tergrund. Diese Bevölkerungsgruppe ist tendenziell in Quartieren mit günstigem Wohnraum übervertreten und wird aufgrund von kulturellen, finanziellen und sozialen Hürden von zahlreichen Massnahmen der Bewegungsförderung nicht erreicht. Deshalb setzt Rock the Block in genau diesen Quartieren an und verfolgt dabei folgende Vision:</p>}
            {textScreen && <p ref = {textRef}>Ausreichend Bewegung ist grundlegend für das menschliche Wohlbefinden. Bewegen sich Menschen zu wenig, hat dies schwerwiegende und langfristige Folgen für ihre physische und psychische Gesundheit. Bewegungsmangel betrifft oftmals Menschen mit tiefem sozioökonomischem Status und Migrationshintergrund. Diese Bevölkerungsgruppe ist tendenziell in Quartieren mit günstigem Wohnraum übervertreten und wird aufgrund von kulturellen, finanziellen und sozialen Hürden von zahlreichen Massnahmen der Bewegungsförderung nicht erreicht. Deshalb setzt Rock the Block in genau diesen Quartieren an und verfolgt dabei folgende Vision:</p>}
            
            <div ref = {quoteRef} style = {{height: gridScreen ? quoteHeight : 'auto', minHeight: !gridScreen ? '20vh' : '0', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px', boxSizing: 'border-box'}}>
            
                <div className = "quote">{
                    <AnimatePresence>
                        {quoteDiv &&
                            quoteArray.map((item, index) => (
    
                                <motion.span
                                key={index}
                                initial={{x: -15, y: 15, opacity: 0}}
                                animate={{x: 0, y: 0, opacity: 1}}
                                transition={{duration: 0.15, delay: index * 0.15}}
                                style = {{display: 'inline-block', marginRight: '0.16em'}}
                                >{item}</motion.span>
                            
                        ))
                        }
                    </AnimatePresence>
                }</div>
            
            </div>

            </div>

            <div className = "grid-image"><img src = "/images/design/learn.jpg" alt = "Seilziehen"/></div>

            </div>

            <div>

            <h1>Was ist Rock the Block?</h1>
            <div className = {gridScreen ? "ausgangslage-grid-left" : ""}>

            <div className = "grid-image"><img src = "/images/design/balance.jpg" alt = "Balancieren"/></div>

            <div className = "grid-text">
            <p>Ins Leben gerufen von vier Sportwissenschaftler:innen der Universität Bern, versucht das Projekt Rock the Block mit kostenlosen Bewegungsangeboten, die draussen, direkt im Quartier stattfinden, das Bewegungsverhalten der Bewohner:innen nachhaltig positiv zu verändern. Das Projekt Rock the Block basiert auf den folgenden drei Standbeinen: </p>

            <div ref = {logoRef} className = "logo-holder">
                {logoDiv &&
                    <motion.div 
                    className = "logo-container"
                    initial = {{x: 300}}
                    animate = {controlsLogo}
                    >
                        <img src = "/images/logos/RockLogo.png" className = "logo-part"/>
                        <img src = "/images/logos/coach.png" className = "coach-part" onClick = {() => handleLogoClick('coach')}/>
                        <img src = "/images/logos/box.png" className = "box-part" onClick = {() => handleLogoClick('box')}/>
                        <img src = "/images/logos/block.png" className = "block-part" onClick = {() => handleLogoClick('block')}/>
                    </motion.div>
                }
            </div>
            </div>

            </div>

            <div className = {gridScreen ? "ausgangslage-grid" : ""}>

            <div className = "logo-text">
                    <p id = "logo-title-one" className = {backgroundSetter === 'block' ? "active-logo" : ""} ref = {blockRef}>Rock the Block: </p><p className = "logo-text-text">Angeleitetes Bewegungsangebot am Mittwoch- und Donnerstagnachmittag, bei dem neue Bewegungsideen und Spiele eingeführt werden.</p>
                    <p id = "logo-title-two" className = {backgroundSetter === 'box' ? "active-logo" : ""} ref = {boxRef}>Rock the Box: </p><p className = "logo-text-text">Bewegungsangebot am Samstagnachmittag, bei dem die Kinder Spielgeräte aus einer Spielkiste ausleihen und selbständig miteinander spielen können. Dazu stehen ihnen Spielideenkarten und Bewegungschallenges zur Verfügung und bei Bedarf werden sie von den Leitpersonen vor Ort unterstützt.</p>
                    <p id = "logo-title-three" className = {backgroundSetter === 'coach' ? "active-logo" : ""} ref = {coachRef}>Coaches: </p><p className = "logo-text-text">Neben der Projektleitung fungieren im Quartier wohnhafte Jugendliche als Leitpersonen. Sie helfen bei der Planung, Durchführung und Betreuung der Bewegungsangebote mit und werden von der Projektleitung ausgebildet und laufend unterstützt. Rock the Block wird seit Oktober 2021 erfolgreich im Quartier Kleefeld in Bern umgesetzt. Eine erste Evaluation lieferte positive Ergebnisse.</p>
            </div>

            <div className = "grid-image"><img src = "/images/design/balance-near.jpg" alt = "Balancieren"/></div>

            </div>

            <div className = "portrait">
                <h1 ref = {portraitRef}>Wer sind wir?</h1>
                <h4>Projektleitung:</h4>
                
                <div className = "image-box">
                    <div><img src = "/images/design/alyssa-portrait.jpg" alt = "Alyssa" className = "portrait-image" onClick = {() => handleProfileDiv('Alyssa')}/><p className = "portrait-text" onClick = {() => handleProfileDiv('Alyssa')}>Alyssa Schumacher<img src = "/icons/down-arrow.png" style = {{transform: profileDiv === 'Alyssa' ? 'rotate(180deg)' : ''}} onClick = {() => handleProfileDiv('Alyssa')}/></p>
                    <AnimatePresence>
                {profileDiv === 'Alyssa' &&
                    <motion.div 
                    className = "portrait-dropdown"
                    key = "profileDiv"
                    initial = {{height: 10}}
                    animate = {{height: 'auto'}}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    >
                        <motion.p 
                        initial = {{scale: 0.5, opacity: 0}}
                        animate = {{scale: 1, opacity: 1}}
                        transition = {{delay: 0.3}}
                        >{profileText}</motion.p>
                    </motion.div>
                }
                </AnimatePresence>
                    </div>
                    <div><img src = "/images/design/nicolas.jpg" alt = "Nicolas" className = "portrait-image" onClick = {() => handleProfileDiv('Nicolas')}/><p className = "portrait-text" onClick = {() => handleProfileDiv('Nicolas')}>Nicolas Tognini<img src = "/icons/down-arrow.png" style = {{transform: profileDiv === 'Nicolas' ? 'rotate(180deg)' : ''}} onClick = {() => handleProfileDiv('Nicolas')}/></p>
                    <AnimatePresence>
                {profileDiv === 'Nicolas' &&
                    <motion.div 
                    className = "portrait-dropdown"
                    key = "profileDiv"
                    initial = {{height: 10}}
                    animate = {{height: 'auto'}}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    >
                        <motion.p 
                        initial = {{scale: 0.5, opacity: 0}}
                        animate = {{scale: 1, opacity: 1}}
                        transition = {{delay: 0.3}}
                        >{profileText}</motion.p>
                    </motion.div>
                }
                </AnimatePresence>
                    </div>
                    <div><img src = "/images/design/jan-portrait.jpg" alt = "Jan" className = "portrait-image" onClick = {() => handleProfileDiv('Jan')}/><p className = "portrait-text" onClick = {() => handleProfileDiv('Jan')}>Jan Wohlgemuth<img src = "/icons/down-arrow.png" style = {{transform: profileDiv === 'Jan' ? 'rotate(180deg)' : ''}} onClick = {() => handleProfileDiv('Jan')}/></p>
                    <AnimatePresence>
                {profileDiv === 'Jan' &&
                    <motion.div 
                    className = "portrait-dropdown"
                    key = "profileDiv"
                    initial = {{height: 10}}
                    animate = {{height: 'auto'}}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    >
                        <motion.p 
                        initial = {{scale: 0.5, opacity: 0}}
                        animate = {{scale: 1, opacity: 1}}
                        transition = {{delay: 0.3}}
                        >{profileText}</motion.p>
                    </motion.div>
                }
                </AnimatePresence>
                    </div>
                    <div><img src = "/images/design/ramon-portrait.jpg" alt = "Ramon" className = "portrait-image" onClick = {() => handleProfileDiv('Ramon')}/><p className = "portrait-text" onClick = {() => handleProfileDiv('Ramon')}>Ramon Felix<img src = "/icons/down-arrow.png" style = {{transform: profileDiv === 'Ramon' ? 'rotate(180deg)' : ''}} onClick = {() => handleProfileDiv('Ramon')}/></p>
                    <AnimatePresence>
                {profileDiv === 'Ramon' &&
                    <motion.div 
                    className = "portrait-dropdown"
                    key = "profileDiv"
                    initial = {{height: 10}}
                    animate = {{height: 'auto'}}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    >
                        <motion.p 
                        initial = {{scale: 0.5, opacity: 0}}
                        animate = {{scale: 1, opacity: 1}}
                        transition = {{delay: 0.3}}
                        >{profileText}</motion.p>
                    </motion.div>
                }
                </AnimatePresence>
                    </div>
                    <div><img src = "/images/design/Pascal.jpeg" alt = "Pascal" className = "portrait-image" onClick = {() => handleProfileDiv('Pascal')}/><p className = "portrait-text" onClick = {() => handleProfileDiv('Pascal')}>Pascal Reichens<img src = "/icons/down-arrow.png" style = {{transform: profileDiv === 'Pascal' ? 'rotate(180deg)' : ''}} onClick = {() => handleProfileDiv('Pascal')}/></p>
                    <AnimatePresence>
                    {profileDiv === 'Pascal' &&
                    <motion.div 
                    className = "portrait-dropdown"
                    key = "profileDiv"
                    initial = {{height: 10}}
                    animate = {{height: 'auto'}}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    >
                        <motion.p 
                        initial = {{scale: 0.5, opacity: 0}}
                        animate = {{scale: 1, opacity: 1}}
                        transition = {{delay: 0.3}}
                        >{profileText}</motion.p>
                    </motion.div>
                }
                    </AnimatePresence>
                    </div>
                </div>

            </div>
            
            <h1 ref = {factsRef}>Fakten & Zahlen</h1>
            <div className = "present-data">
                <p>Rückblick 2023</p>
                <div className = "card-container">
                    {!gridScreen &&
                    <>
                   <motion.div 
                   ref = {firstRef}
                   initial = "hidden"
                   whileInView = "visible"
                   viewport={{once: true}}
                   transition = {{duration: 0.8, type: "spring", bounce: 0.4}}
                   variants = {{
                    visible: {opacity: 1, y: 0},
                    hidden: {opacity: 0, y: 200}
                   }}
                   >
                        <div className = "card"><h1>{firstNumber}</h1><p>Kinder nahmen durchscnittlich an den Angeboten teil.</p><div className = "card-icon" ><img src = "/icons/kids.png"/></div></div>
                        
                   </motion.div>

                   <motion.div 
                   ref = {secondRef}
                   initial = "hidden"
                   whileInView = "visible"
                   viewport={{once: true}}
                   transition = {{duration: 0.8, type: "spring", bounce: 0.4}}
                   variants = {{
                    visible: {opacity: 1, y: 0},
                    hidden: {opacity: 0, y: 200}
                   }}
                   >
                        <div className = "card" ><h1>{secondNumber}</h1><p>Stunden Aus- und Weiterbildung von jugendlichen Coaches.</p><div className = "card-icon"><img src = "/icons/stopwatch.png" /></div></div>
                   </motion.div>

                   <motion.div 
                   ref = {thirdRef}
                   initial = "hidden"
                   whileInView = "visible"
                   viewport={{once: true}}
                   transition = {{duration: 0.8, type: "spring", bounce: 0.4}}
                   variants = {{
                    visible: {opacity: 1, y: 0},
                    hidden: {opacity: 0, y: 200}
                   }}
                   >
                        <div className = "card" ><h1>{thirdNumber}</h1><p>Stunden Aus- und Weiterbildung von jugendlichen Coaches.</p><div className = "card-icon"><img src = "/icons/race.png" /></div></div>
                   </motion.div>

                   <motion.div 
                   ref = {fourthRef}
                   initial = "hidden"
                   whileInView = "visible"
                   viewport={{once: true}}
                   transition = {{duration: 0.8, type: "spring", bounce: 0.4}}
                   variants = {{
                    visible: {opacity: 1, y: 0},
                    hidden: {opacity: 0, y: 200}
                   }}
                   >
                        <div className = "card" ><h1>{fourthNumber}</h1><p>Angebote hat Rock the Block von Januar bis Dezember durchgeführt.</p><div className = "card-icon"><img src = "/icons/fitness.png" /></div></div>
                   </motion.div>

                   <motion.div 
                   ref = {fifthRef}
                   initial = "hidden"
                   whileInView = "visible"
                   viewport={{once: true}}
                   transition = {{duration: 0.8, type: "spring", bounce: 0.4}}
                   variants = {{
                    visible: {opacity: 1, y: 0},
                    hidden: {opacity: 0, y: 200}
                   }}
                   >
                        <div className = "card" ><h1>{fifthNumber}</h1><p>Mit dieser Note wird Rock the Block von den Anwohner:innen bewertet.</p><div className = "card-icon"><img src = "/icons/graduate-cap.png" /></div></div>
                   </motion.div>

                   <motion.div 
                   ref = {sixthRef}
                   initial = "hidden"
                   whileInView = "visible"
                   viewport={{once: true}}
                   transition = {{duration: 0.8, type: "spring", bounce: 0.4}}
                   variants = {{
                    visible: {opacity: 1, y: 0},
                    hidden: {opacity: 0, y: 200}
                   }}
                   >
                        <div className = "card" ><h1>{sixthNumber + '%'}</h1><p>der Bewohner:innen empfehlen Rock the Block auch für andere Quartiere.</p><div className = "card-icon"><img src = "/icons/location.png" /></div></div>
                   </motion.div>
                   </>
                  }
                  {gridScreen &&
                    <div className = "data-grid" ref = {desktopRef}>
                        <div>
                        <div className = "grid-card"><h1>{firstNumber}</h1><p>Kinder nahmen durchschnittlich an den Angeboten teil.</p><div className = "card-icon" ><img src = "/icons/kids.png"/></div></div>
                        <div className = "grid-card"><h1>{secondNumber}</h1><p>Stunden Aus- und Weiterbildung von jugendlichen Coaches.</p><div className = "card-icon"><img src = "/icons/stopwatch.png" /></div></div>
                        <div className = "grid-card"><h1>{thirdNumber}</h1><p>Stunden Aus- und Weiterbildung von jugendlichen Coaches.</p><div className = "card-icon"><img src = "/icons/race.png" /></div></div>
                        </div>

                        <div>
                        <div className = "grid-card"><h1>{fourthNumber}</h1><p>Angebote hat Rock the Block von Januar bis Dezember durchgeführt.</p><div className = "card-icon-right"><img src = "/icons/fitness.png" /></div></div>
                        <div className = "grid-card"><h1>{fifthNumber}</h1><p>Mit dieser Note wird Rock the Block von den Anwohner:innen bewertet.</p><div className = "card-icon-right"><img src = "/icons/graduate-cap.png" /></div></div>
                        <div className = "grid-card"><h1>{sixthNumber + '%'}</h1><p>der Bewohner:innen empfehlen Rock the Block auch für andere Quartiere.</p><div className = "card-icon-right"><img src = "/icons/location.png" /></div></div>
                        </div>

                        <div className = "upper-div"><p>An Tagen, an denen ein Bewegungsangebot stattfindet, befinden sich <p className = "bold-text">mehr Kinder aktiv draussen</p> im Quartier.</p></div>

                        <div className = "lower-div"><p>An Tagen, an denen ein Bewegungsangebot stattfindet, <p className = "bold-text">bewegen</p> sich die teilnehmenden Kinder <p className = "bold-text">länger</p> mit mittlerer und hoher Intensität.</p></div>
                    </div>
                  }
                </div>
            </div>

        </div>

        </div>

        </>
    );
}

export default Viewer;